import React from 'react';
import AboutMe from './AboutMe';
import './App.css';

function App() {
  return (
    <div className="App">
      <AboutMe />
    </div>
  );
}

export default App;
